<template>
  <div>
    <b-card>
      <h3 class="mb-1 card-h3">
        线路基础信息
      </h3>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span>线路名称</span>
        <span class="ml-2">澳洲直邮台湾集运</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span>运输方式</span>
        <span class="ml-2">空运</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span>发货日期</span>
        <span class="ml-2">每周三截单，周四发货</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span>一票多单</span>
        <span class="ml-2">否</span>
      </div>
      <hr>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span class="text-right">单个包裹最大重量限制</span>
        <span class="ml-2">30kg</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span class="text-right">单个包裹单边限制</span>
        <span class="ml-2">200cm</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span class="text-right">单个包裹两短边之和限制</span>
        <span class="ml-2">150cm</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span class="text-right">单个包裹最大体积重</span>
        <span class="ml-2">35kg</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span class="text-right">物流时效</span>
        <span class="ml-2">5-10个工作日</span>
      </div>
      <div class="info-text d-flex justify-content-start align-items-center">
        <span class="text-right">可发属性</span>
        <span class="ml-2">食品 保健品 个人行李箱</span>
      </div>
    </b-card>
    <b-card>
      <h3 class="mb-1 card-h3">
        代理点成本
      </h3>
      <div class="cost mb-2 d-flex justify-content-start align-items-center">
        <span class="text-right-sec">计费方式</span>
        <span class="ml-3">Real Weight</span>
      </div>
      <div class="cost mb-2 d-flex justify-content-start align-items-center">
        <span class="text-right-sec">首重</span>
        <span class="ml-3">AU$8.00/1000g</span>
      </div>
      <div class="cost mb-2 d-flex justify-content-start align-items-center">
        <span class="mr-3 text-right-sec">续重</span>
        <b-table
          :fields="fields"
          responsive="sm"
          :items="items"
          bordered
        >
          <template #cell(price)="data">
            <span>AU${{ data.value }}</span>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card>
      <div class="card-h3 d-flex justify-content-between align-items-center mb-1">
        <h3
          style="margin:0"
        >
          对外收费
        </h3>
        <div>
          <b-button
            v-if="!isEdit"
            variant="relief-primary"
            @click="isEdit = true"
          >
            修改
          </b-button>
          <b-button
            v-else
            variant="relief-primary"
            @click="isEdit = false"
          >
            保存
          </b-button>
          <b-button
            variant="relief-success"
            class="ml-1"
          >
            恢复系统默认
          </b-button>
        </div>
      </div>
      <div class="cost mb-2 d-flex justify-content-start align-items-center">
        <span class="text-right-sec">计费方式</span>
        <div>
          <b-form-select
            v-model="selected"
            class="ml-3"
            :disabled="!isEdit"
            :options="options"
          />
        </div>
      </div>
      <div class="cost mb-2 d-flex justify-content-start align-items-center">
        <span class="text-right-sec">首重</span>
        <div class="ml-3 d-flex justify-content-start">
          <b-input-group append="g">
            <b-form-input
              placeholder="100"
              :disabled="!isEdit"
            />
          </b-input-group>
          <b-input-group
            prepend="AU$"
            class="ml-1"
          >
            <b-form-input
              placeholder="1.00"
              :disabled="!isEdit"
            />
          </b-input-group>
        </div>
      </div>
      <div class="cost mb-2 d-flex justify-content-start align-items-center">
        <span class="mr-3 text-right-sec">续重</span>
        <b-table
          :fields="fields"
          responsive="sm"
          :items="items"
          bordered
        >
          <template #cell(price)="data">
            <span>AU${{ data.value }}</span>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BFormSelect, BFormInput, BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormSelect,
    BFormInput,
    BInputGroup,
  },
  data() {
    return {
      items: [{
        weight_start: '1000g',
        weight_end: '3000g',
        continued: '100g',
        price: '0.8',
      }],
      fields: [{
        key: 'weight_start', label: '重量范围(Start)',
      }, {
        key: 'weight_end', label: '重量范围(End)',
      }, {
        key: 'continued', label: '续重',
      }, {
        key: 'price', label: '进位价格',
      }],
      selected: 1,
      options: [
        { value: 1, text: 'Real Weight' },
        { value: 2, text: 'Max 5000' },
      ],
      isEdit: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.info-text{
  font-size: 20px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.text-right,.text-right-sec{
  display: block;
  width: 140px;
}
.text-right-sec{
  text-align: right;
}
.cost{
  font-size: 20px;
}
</style>
